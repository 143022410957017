const ChallengeCards = [
    {
        title: "TaylORK Swift",
        img: "swift.jpg",
    },
    {
        title: "8-bit",
        img: "nintendo.png",
    },
    {
        title: "rave",
        img: "rave.jpg",
    },
    {
        title: "rap",
        img: "rap.jpg",
    },
    {
        title: "Future Music '93",
        img: "future_music.jpg",
    },
    {
        title: "sommerlåt",
        img: "sommer.jpg",
    },
    {
        title: "bond",
        img: "bond.jpg",
    },
    {
        title: "grunge",
        img: "grunge2.jpg",
    },
    {
        title: "protestvise",
        img: "protest.jpg",
    },
    {
        title: "Depeche Mode",
        img: "depeche.jpg",
    },
    {
        title: "a cappella",
        img: "acapella.jpg",
    },
    {
        title: "ballade",
        img: "ballade.jpg",
    },
    {
        title: "sjangerbingo",
        img: "bingo.jpg",
    },
    {
        title: "rock - åpen klasse",
        img: "rock.jpg",
    },
];

const winnerSongs = [
    {
        oppgave: "8-bit",
        vinner: "Eski Mukasato",
        svg: "8bit.svg",
        image: "nintendo.png",
        fotograf: "ORK",
        periode: "juni 2023",
        oppgavetekst:
            "Lyden av gamle TV-spill er noe mange har et spesielt forhold til. Fra Nintendo til Game Boy, Commodore 64 og Amiga-konsoller, lydene som disse maskinene produserte har vokst til å bli egne sjangre med dedikerte plateselskaper og festivaler. Denne oppgaven er å lage soundtracket til et spill fra perioden 1985 til 1992. 🕹️🍄 ",
        songTitle: "Monkey's Quest",
        url: require(`@/assets/audio/nintendo.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Bond-låt",
        vinner: "Øyvind",
        svg: "bond.svg",
        image: "bond.jpg",
        fotograf: "The Nix Company",
        periode: "mars 2020",
        oppgavetekst:
            "Å forsøke å lage en Jame Bond-låt i fullt alvor er nok noe av det kleineste man kan gjøre. I anledning premieren på James Bond-filmen No Time To Die skal du lage en låt som viser Billie Eilish hvordan det skulle vært gjort. Finn frem din indre Shirley Bassey/Alicia Keys/Tina Turner/Morten Harket/Paul McCartney. God fornøyelse",
        songTitle: "No Time To Die",
        url: require(`@/assets/audio/bond.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Norsk sommerlåt",
        vinner: "Hans Martin",
        svg: "summer.svg",
        image: "sommer.jpg",
        fotograf: "Erik Skarstein",
        periode: "juni 2020",
        oppgavetekst:
            "Norske sommerlåter vekker mange ulike følelser. For noen er en klassisk norsk sommerlåt en tidløs, smak av honning om sola som skinner. Hos andre fremkaller en sterk brekningsrefleks og migrene. Oppgaven i denne runden er å dekode den norske sommerlåta og skrive en sommerlåt slik du mener den skal være. Stikkord: Sommer, sol, båt, krabber, middag-klokka-to-i-morra. Hvis du leverer på denne kan du jo si at du var glad at du turte.☀️",
        songTitle: "Barnløs med Pappakropp",
        url: require(`@/assets/audio/sommer.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Protestvise",
        vinner: "Brother Erik & the Bastøy Boys",
        svg: "protest.svg",
        image: "protest.jpg",
        fotograf: "Markus Spiske",
        periode: "mai 2021",
        oppgavetekst:
            "Protestsanger har lange tradisjoner innen mange ulike musikksjangre. Oppgaven i denne runden er å lage en protsetsang. På tide å sette foten ned og si klart fra! Bob Dylan fyller tilfeldigvis 80 år midt i denne runden. Noe å tenke på. Lykke til.",
        songTitle: "Protest!",
        url: require(`@/assets/audio/protest.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "a capella",
        vinner: "Mannskoret Sinsen T",
        svg: "acapella.svg",
        image: "acapella.jpg",
        fotograf: "Bruno Emmanuelle",
        periode: "september 2021",
        oppgavetekst:
            "I denne oppgaven kan du bare bruke din egen stemme. Det skal være fokus på vokal (eller andre lyder du kan lage med eget tryne). Det er tillat med sampling/prosessering, men det skal være fokus på vokalen. Det er ingen sjangermessige føringer, så om du lager et hyggelig korstykke eller elektro-akustisk crazy-stuff er opp til deg. En genuint grusom oppgave for alle som ikke er vant til å synge. God fornøyelse.",
        songTitle: "Problemer",
        url: require(`@/assets/audio/acappella.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Rave/klubb",
        vinner: "Bernt Isak",
        svg: "rave.svg",
        image: "rave.jpg",
        fotograf: "Pim Myten",
        periode: "november 2021",
        oppgavetekst:
            "I denne oppgaven skal du lage noe raff klubbmusikk som skal få en gjeng på et dansegulv til å svinge på dansefoten. Vanlige regler gjelder: Gjør alt selv. Du velger selv hvordan du avvikler fremføringen: DJing, avspilling, livespilling, etc. Det er mange påmeldte denne gangen, og det er derfor en tidsbegrensning på 5 minutter. Det vil være publikum denne gangen. Kvelden markerer også ORKs femårsdag. 🥳 🎸 5️⃣ Lykke til.",
        songTitle: "Do You Wanna Dance?",
        url: require(`@/assets/audio/rave.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Grunge",
        vinner: "Øyvind",
        svg: "grunge.svg",
        image: "grunge2.jpg",
        fotograf: "Matt Moloney",
        periode: "juni 2019",
        oppgavetekst:
            'På tide å dykke ned i litt mørk, dystopisk og angstfylt støyrock: Lag en grunge-låt. Vanlige regler gjelder. Når det gjelder soloplikten i denne oppgaven er det kanskje mest grunge-aktig å beholde den, men nekte å spille solo. For som det står på wikipedia: "Grunge guitarists "flatly rejected" the virtuoso "shredding" guitar solos that had become the centerpiece of heavy metal songs(...)". Føkk off (på en hyggelig måte).',
        songTitle: "Fuck you too",
        url: require(`@/assets/audio/grunge.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Ballade",
        vinner: "Øyvind",
        svg: "ballade.svg",
        image: "ballade.jpg",
        fotograf: "Vitaly Shevchenko",
        periode: "januar 2019",
        oppgavetekst:
            "Ingenting er som å utlevere følelsene sine over noen såre mollakkorder. Denne runden er ballade, åpen klasse. Det er altså ingen sjangermessige føringer, utover at du skal skrive den vakreste balladen du greier å klemme ut. Lykke til. 😘",
        songTitle: "The Spark & the Flame",
        url: require(`@/assets/audio/ballade.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Rap",
        vinner: "Henning",
        svg: "rap.svg",
        image: "rap.jpg",
        fotograf: "Kyle Cleveland",
        periode: "desember 2018",
        oppgavetekst:
            "Denne oppgaven har vært diskutert flere ganger, og er blitt valgt bort siden den er helt på kanten av hva man bør utsette seg selv for. Men her er den altså: ORK 4/18: rap. Vanlige regler, soloplikten utgår siden det er mer enn nok smerte i å skrive og fremføre rap.",
        songTitle: "Kongen av Abildsø",
        url: require(`@/assets/audio/rap.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Rock",
        vinner: "anonym",
        svg: "rock.svg",
        image: "rock.jpg",
        fotograf: "firmbee.com",
        periode: "desember 2016",
        oppgavetekst:
            "Hvor vanskelig er det egentlig å lage en rockelåt? Og hvordan høres det ut om man spiller alle instrumenter selv og tar oppgaven helt seriøst? La oss gi det et forsøk.",
        songTitle: "Far From Here",
        url: require(`@/assets/audio/rock.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Kalas/norsk partymusikk",
        vinner: "anonym",
        svg: "kalas.svg",
        image: "kalas.jpg",
        fotograf: "Kim Erlandsen, NRK P3 (CC)",
        periode: "november 2022",
        oppgavetekst:
            "Kalas er et norsk ord som ofte brukes om en fest eller en samling med hyggelig atmosfære. Ordet kommer fra det svenske ordet 'kalas', som også betyr fest eller feiring. Norsk festmusikk har de siste årene utviklet seg til en egen sjanger som treffer bredt og i mange målgrupper. Hva er det med denne sjangeren som gjør den så populær? Hva skal til for å lage en låt som funker like godt på afterski i Hemsedal, Allsang på Grensen og i barnebursdag? Oppgaven i denne runden er å dekode norsk partymusikk, finne frem dine mest joviale sider og lage enn låt i sjangeren moderne norsk partymusikk. God fornøyelse.",
        songTitle: "Gutta (til det smeller)",
        url: require(`@/assets/audio/kalas.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Depeche",
        vinner: "Øyvind",
        svg: "depeche.svg",
        image: "depeche.jpg",
        fotograf: "Nasjonalbiblioteket/Kåre Eide",
        periode: "mars 2017",
        oppgavetekst:
            "Depeche Mode har siden 1981 vært et av verdens mest betydningsfulle synthrockband, med over 100 millioner solgte plater. I denne runden skal du velge en plate fra Depeches store katalog og lage låta som mangler på plata. Stikkord: Synther, chorus, digital klang og trommemaskiner.",
        songTitle: "In the Dust of the Moment",
        url: require(`@/assets/audio/depeche.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Future Music",
        vinner: "Henning",
        svg: "future_music.svg",
        image: "future_music.jpg",
        fotograf: "ORK",
        periode: "september 2019",
        oppgavetekst:
            "En gang i 1993 publiserte musikkbladet Future Music den aller første (av svært mange) sample-CDer. PÅ plata finner man samples laget av Norman Cook, før han laøte seg Fatboy Slim og Coldcut. Denne ikoniske samlingen med lyder er samplet, slicet, trimmet, pitchet og resamplet av folk som lager elektronisk musikk i 30 år. Oppgaven i denne runden er å lage en løåt utelukkende med lydene på Future Musics sampleplate fra 1993. Lykke til.",
        songTitle: "Drummer Get Wicked",
        url: require(`@/assets/audio/future_music.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Sjangerbingo",
        vinner: "Hans Martin",
        svg: "bingo.svg",
        image: "bingo.jpg",
        fotograf: "ORK",
        periode: "desember 2019",
        oppgavetekst:
            "Denne runden er et eksperiment. Siden det er kommet inn mange forslag denne gangen kjører vi lotteri. Forslagene som er kommet inn er: Progrock, svensk elektropop, nintendocore,funk, reggae, black metal og frijazz. De som er med i denne runden får tildelt en sjanger. Vanlige regler gjelder. Lykke til!",
        songTitle: "Booty in a Movie",
        url: require(`@/assets/audio/bingo.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Tailork Swift",
        vinner: "Øyvind",
        svg: "tailorkswift.svg",
        image: "swift.jpg",
        fotograf: "Raphael Lovaski",
        periode: "mars 2023",
        oppgavetekst:
            "Taylor Swift vokste opp på en juletrefarm i Wyomissing i Pennsylvania. Hun har definert moderne amerikansk popmusikk og vunnet ti Grammy Awards, nitten American Music Awards, elleve Country Music Association Awards, åtte Academy of Country Music Awards, tjueto Billboard Music Awards, en Brit Award og en Emmy. Idenne oppgaven skal du skrive, spille inn og produsere en låt som kunne vært skrevet av, produsert for og fremført av Taylor Swift. Vanlige regler gjelder. Lykke til!",
        songTitle: "Not my Future",
        url: require(`@/assets/audio/swift.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Danseband",
        vinner: "Øyvind",
        svg: "danseband.svg",
        image: "danseband.jpg",
        fotograf: "Jens Thekkeveettil",
        periode: "august 2023",
        oppgavetekst:
            "Dansebandsjangeren har i følge wikipedia sine røtter i britisk-amerikansk femti- og sekstitalls pop, men den har også tatt opp i seg trekk fra andre sjangre, som rock'n roll, tysk slagermusikk, country, og tradisjonell folkemusikk. Det er kanskje den mest folkelige musikksjangeren vi har i Norge, og sjangeren har godt nedslag i Sverige, Danmark Tyskland, Nederland og Østerrike. Oppgaven i denne runden er å dekode dansebandsjangeren og levere en låt som kunne vært med med på en kompilasjon av det beste innen sjangeren. Minner om regel 2: En viktig del av oppgaven er å ta dette 100% seriøst. Soloplikten gjelder. 🎷",
        songTitle: "Husvagn Uten Dig",
        url: require(`@/assets/audio/danseband.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
    {
        oppgave: "Svartmetall",
        vinner: "Fossegrim",
        svg: "blackmetal.svg",
        image: "ork_blackmetal_crop.jpg",
        fotograf: "ORK",
        periode: "desember 2023",
        oppgavetekst:
            "Black metal, også kjent som svartmetall eller satanrock, er en undersjanger av heavy metal som generelt regnes for å være ekstrem og ukommersiell. Sjangeren er ofte knyttet opp mot myter og klisjeer, som oftest basert på satanisme og det okkulte, men også om natur og filosofi. Sjangerens opprinnelse stammer fra omkring 1980, men det var på slutten av tiåret at moderne black metal ble utformet av norske band med særegen vokalteknikk og 'corpse paint'-sminke. Sjangeren har også fått en del oppmerksomhet for kirkebranner og drap. Oppgaven i denne runden er å lage en låt i sjangeren norsk black metal, fra valgfri periode og stilretning. For de som ikke kan spille i 230 med dobbel basstrommepedal er det lov å programmere trommer. Synther er også lov, siden det er etablerte band i sjangeren som bruker slikt. Kommenter under om det noen som er uenige.",
        songTitle: "Høstmørke",
        url: require(`@/assets/audio/blackmetal.mp3`),
        kommentar:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium aperiam, rem libero sunt quisquam odit aliquid possimus labore dolore similique inventore, aliquam illum. Veritatis porro temporibus expedita blanditiis maxime laborum eum placeat. Enim fug incidunt nobis. Accusantium aut hic deleniti architecto molestiae mollitia, quaerat dolorem atque, possimus, itaque quae exercitationem.",
    },
];

export { ChallengeCards, winnerSongs };
