<template>
    <the-header />
    <router-view name="content"></router-view>
    <the-footer />
</template>

<script>
export default {
    data() {
        return {
            user: null,
            menuOpen: null,
        };
    },
};
</script>

<Style>

</Style>
