<template>
    <footer class="flex bg-black pb-4">
        <div
            class="containerflex text-white h-fit flex-col justify-between w-full md:w-3/5 lg:w-3/5 xl:max-w-5xl mx-auto">
            <div class="w-fit">
                <ul class="py-12">
                    <li class="flex items-center link">
                        <router-link
                            class="flex items-center"
                            to="/personvern">
                            <p class="text-xl pl-4 tracking-wider pt-2">
                                personvern
                            </p>

                            <svg
                                class="pl-2 w-10 stroke-yellow-300"
                                id="footer-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="12">
                                <g
                                    stroke="#ffffff"
                                    class="stroke-yellow-300"
                                    stroke-width="3">
                                    <path
                                        stroke-linecap="square"
                                        d="M3 9.879 9.879 3" />
                                    <path d="M13 1.5H1M11.5 12V0" />
                                </g>
                            </svg>
                        </router-link>
                    </li>
                    <li class="flex items-center link">
                        <a
                            href="https://www.instagram.com/oslo_rockekonkurranse/"
                            class="flex items-center"
                            to="/personvern">
                            <p class="text-xl pl-4 tracking-wider pt-2">
                                instagram
                            </p>

                            <svg
                                class="pl-2 w-10 stroke-yellow-300"
                                id="footer-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="12">
                                <g
                                    stroke="#ffffff"
                                    class="stroke-yellow-300"
                                    stroke-width="3">
                                    <path
                                        stroke-linecap="square"
                                        d="M3 9.879 9.879 3" />
                                    <path d="M13 1.5H1M11.5 12V0" />
                                </g>
                            </svg>
                        </a>
                    </li>
                    <li class="flex items-center link">
                        <router-link
                            class="flex items-center"
                            to="/kontakt">
                            <p class="text-xl pl-4 tracking-wider pt-2">
                                kontakt
                            </p>

                            <svg
                                class="pl-2 w-10 stroke-yellow-300"
                                id="footer-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="12">
                                <g
                                    stroke="#ffffff"
                                    class="stroke-yellow-300"
                                    stroke-width="3">
                                    <path
                                        stroke-linecap="square"
                                        d="M3 9.879 9.879 3" />
                                    <path d="M13 1.5H1M11.5 12V0" />
                                </g>
                            </svg>
                        </router-link>
                    </li>
                    <li class="flex items-center link">
                        <router-link
                            class="flex items-center"
                            to="/kredd">
                            <p class="text-xl pl-4 tracking-wider pt-2">
                                kredd
                            </p>

                            <svg
                                class="pl-2 w-10 stroke-yellow-300"
                                id="footer-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="12">
                                <g
                                    stroke="#ffffff"
                                    class="stroke-yellow-300"
                                    stroke-width="3">
                                    <path
                                        stroke-linecap="square"
                                        d="M3 9.879 9.879 3" />
                                    <path d="M13 1.5H1M11.5 12V0" />
                                </g>
                            </svg>
                        </router-link>
                    </li>
                    <li class="flex items-center link">
                        <router-link
                            class="flex items-center"
                            to="/om">
                            <p class="text-xl pl-4 tracking-wider pt-2">om</p>

                            <svg
                                class="pl-2 w-10 stroke-yellow-300"
                                id="footer-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="12">
                                <g
                                    stroke="#ffffff"
                                    class="stroke-yellow-300"
                                    stroke-width="3">
                                    <path
                                        stroke-linecap="square"
                                        d="M3 9.879 9.879 3" />
                                    <path d="M13 1.5H1M11.5 12V0" />
                                </g>
                            </svg>
                        </router-link>
                    </li>
                </ul>
                <!--            <ul class="pt-12">
                    <li
                        v-for="item in footerItems"
                        :key="item.index"
                        class="flex items-center link">
                        <router-link
                            class="flex items-center"
                            :to="item.path">
                            <p class="text-xl pl-4 tracking-wider pt-2">
                                {{ item.text }}
                            </p>

                            <svg
                                class="pl-2 w-10 stroke-yellow-300"
                                id="footer-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="12">
                                <g
                                    stroke="#ffffff"
                                    class="stroke-yellow-300"
                                    stroke-width="3">
                                    <path
                                        stroke-linecap="square"
                                        d="M3 9.879 9.879 3" />
                                    <path d="M13 1.5H1M11.5 12V0" />
                                </g>
                            </svg>
                        </router-link>
                    </li>
                </ul> -->
            </div>
            <div class="flex h-46 flex-col w-fit m-4">
                <span class="border-t-2 border-white self-center w-full"></span>
                <div class="pt-4">
                    <p class="text-sm text-center">
                        © 2016-2023 Oslo Rockekonkurranse. Bilder/video/lyd og
                        annet innhold på denne siden er brukt med tillatelse
                        under Creative Commons, Public Domain-lisensiering eller
                        med annen tillatelse fra opphaver. Det er ikke tillat å
                        kopiere, bruke eller gjengi innhold, lyd, tekst, bilder
                        eller annet materiale fra denne nettsiden uten skriftlig
                        tillatelse.
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            footerItems: [
                { text: "personvern", path: "/personvern", url: "" },
                {
                    text: "instagram",
                    path: "",
                    url: "https://www.instagram.com/oslo_rockekonkurranse/",
                },
                { text: "kontakt", path: "/", url: "" },
                { text: "kredd", path: "/kredd", url: "" },
                { text: "om", path: "/om", url: "" },
            ],
        };
    },
};
</script>

<style scoped>
#footer-svg {
    margin-top: 12px;
}
</style>
