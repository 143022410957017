<template>
  <div class="pb-60 sm:pb-96">
    <div class="flex flex-col items-center">
      <img
        class="absolute brightness -z-30 md:max-w-3xl"
        :src="require(`@/assets/images/${image}`)"
        alt=""
      />
      <div
        class="-z-30 rounded-sm w-5/6 relative translate-y-56 sm:translate-y-96 h-fit bg-white shadow-md flex flex-col items-center p-4 md:mt-20"
      >
        <h2 class="text-3xl pb-4">{{ title }}</h2>
        <p class="text-md text-center">{{ intro }}</p>
        <p class="text-sm pt-4 text-gray-600">{{ updated }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["image", "title", "intro", "updated"],
  data() {
    return {};
  },
};
</script>
