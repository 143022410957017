<template>
    <div>
        <div
            :key="song"
            class="w-96 -z-300 pl-4 bg-gray-100 py-2">
            <div class="h-full flex items-center">
                <div>
                    <img
                        class="h-16 w-16 object-cover mr-1 shrink-0"
                        :src="require(`@/assets/images/${song.image}`)" />
                </div>
                <div class="pl-2 flex flex-col grow">
                    <div class="flex items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z" />
                        </svg>

                        <p class="text-lg ml-1 whitespace-nowrap">
                            {{ song.songTitle }}
                        </p>
                    </div>

                    <div class="flex items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>

                        <p class="text-sm ml-1 whitespace-nowrap">
                            {{ song.vinner }}
                        </p>
                    </div>
                    <div class="flex items-center pt-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                        </svg>

                        <p class="text-sm ml-1">{{ song.oppgave }}</p>
                    </div>
                </div>
                <div class="flex items-center">
                    <p class="pr-1 text-sm">{{ audioStore.timer }}</p>
                    <svg
                        @click="stopAudio"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-7 h-7 flex-end ml-auto mr-5 fill-gray-100 hover:cursor-pointer">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useAudioStore } from "@/assets/stores/audioStore.js";
export default {
    setup() {
        const audioStore = useAudioStore();
        const stopAudio = () => {
            audioStore.stopAudio();
        };
        return { audioStore, stopAudio };
    },

    computed: {
        song() {
            return this.audioStore.theCurrentSong;
        },
    },

    watch: {
        songUpdate() {
            this.song = this.audioStore.theCurrentSong;
        },
    },
};
</script>

<style scoped>
#oppgave {
    margin-left: 2px;
}

#oppgavetekst {
    margin-right: 2px;
}
</style>
