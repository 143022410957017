<template>
    <section class="w-full py-8">
        <div
            :index="song.index"
            v-for="song in songs"
            :key="song.index"
            id="winnercard"
            class="bg-gradient-to-t from-blue-100 via-white to-transparent pt-10">
            <div
                class="h-96 w-full rounded-md rounded-b-none"
                :style="{
                    'background-image':
                        'url(' + require(`@/assets/images/${song.image}`) + ')',
                    'background-size': 'cover',
                }">
                <div
                    class="flex flex-col justify-center items-center w-full pt-1">
                    <img
                        @click="print"
                        class="relative w-60 mt-80 pt-3"
                        :src="require(`@/assets/svg/${song.svg}`)" />
                </div>
            </div>

            <!-- Description -->
            <div
                class="w-full h-12 pt-10 flex justify-center items-center border border-black border-t-0 border-b-0 flex-col">
                <p>{{ song.periode }}</p>
                <p class="text-gray-700 text-xs">foto: {{ song.fotograf }}</p>
            </div>

            <div
                class="rounded-b-md w-full h-fit border border-black shadow-lg border-t-0 pt-12">
                <!--       <p class="text-xl mx-2 py-4">Norsk sommerlåt</p> -->
                <p class="text-md mx-2 pb-2">Oppgavetekst</p>
                <p class="text-sm mx-2">{{ song.oppgavetekst }}</p>
                <div class="text-md mx-2 mt-4">
                    <div
                        class="flex flex-col w-full items-center justify-center pt-8">
                        <p class="text-xl">🏆</p>
                        <p>Vinner</p>

                        <p class="text-xl">{{ song.vinner }}</p>
                        <p class="pt-4">Låt</p>
                        <p class="text-xl">{{ song.songTitle }}</p>
                    </div>

                    <!-- Audio player -->

                    <div class="h-20 flex m-4 rounded mb-20">
                        <div class="w-full flex justify-center items-center">
                            <div
                                v-if="
                                    !audioStore.audioIsPlaying ||
                                    song != audioStore.currentSong
                                "
                                @click="handlePlay(song)"
                                class="border-2 border-green-500 rounded-full p-3 flex items-center justify-center hover:cursor-pointer hover:border-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="pl-1 w-8 h-8 stroke-gray-800">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                </svg>
                            </div>
                            <div
                                @click="stopAudio(song)"
                                v-if="
                                    song == audioStore.currentSong &&
                                    audioStore.audioIsPlaying
                                "
                                class="border-2 border-orange-500 rounded-full p-3 flex items-center justify-center hover:cursor-pointer hover:border-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-8 h-8 stroke-gray-800">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <!-- timer & seek slider -->
                    <!--               <transition name="slide">
                        <div
                            v-if="song"
                            class="w-full h-fit flex flex-col items-center justify-center">
                            <div class="pb-4"><p>00:00</p></div>

                            <input
                                class="w-5/6 bg-slate-200 hidden"
                                type="range"
                                id="seek-slider"
                                max="100"
                                value="100" />
                        </div>
                    </transition> -->
                    <!--   <div
                        class="w-full h-20 flex flex-col items-center justify-center mt-8 mb-8">
                        <button
                            @click="readComment(song)"
                            class="w-2/3 p-4 rounded-md">
                            <div
                                class="flex flex-col justify-center items-center">
                                <span>Vinnerens kommentar</span>
                                <span v-if="readComment(song)">Lukk</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-4 h-4 rotate-180">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                </svg>
                            </div>
                        </button>
                    </div>
                    <transition name="slide">
                        <div
                            v-if="showComment"
                            class="pb-6">
                            <p class="text-md pb-2">Vinnerens kommentar</p>
                            <p class="text-sm">{{ song.kommentar }}</p>
                        </div></transition> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useAudioStore } from "@/assets/stores/audioStore.js";
export default {
    setup() {
        const audioStore = useAudioStore();

        const handlePlay = (song) => {
            audioStore.play(song);
        };
        const stopAudio = (song) => {
            audioStore.stopAudio(song);
        };

        return { audioStore, handlePlay, stopAudio };
    },
    data() {
        return {
            showComment: false,
            songs: null,
            clickedSong: null,
        };
    },
    methods: {
        readComment(song) {
            console.log(song);
        },
    },

    /* stokker vinnerkortene tilfeldig i tidligere oppgaver */

    mounted() {
        this.songs = this.audioStore.songs.sort(() => Math.random() - 0.5);
    },
};
</script>

<style scoped>
/* transitions */

.slide-enter-from {
    opacity: 0;
    transform: translateY(-50px);
}

.slide-enter-active {
    transition: all 0.5s;
}
.slide-enter-to {
    opacity: 1;
    transform: translateY(0);
}

.slide-leave-from {
    opacity: 1;
    transform: translateY();
}

.slide-leave-active {
    transition: all 0.5s;
}
.slide-leave-to {
    opacity: 0;
    transform: translateY(-50px);
}

/* seek slider til audio player */

input[type="range"] {
    position: relative;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    float: left;
    outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: linear-gradient(
        to right,
        rgba(0, 125, 181, 0.6) var(--buffered-width),
        rgba(0, 125, 181, 0.2) var(--buffered-width)
    );
}
input[type="range"]::before {
    position: absolute;
    content: "";
    top: 8px;
    left: 0;
    width: var(--seek-before-width);
    height: 3px;
    background-color: #007db5;
    cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    box-sizing: content-box;
    border: 1px solid #777777;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #777777;
    cursor: pointer;
    margin: -7px 0 0 0;
}
input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: #007db5;
}
input[type="range"]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: linear-gradient(
        to right,
        rgba(0, 125, 181, 0.6) var(--buffered-width),
        rgba(0, 125, 181, 0.2) var(--buffered-width)
    );
}
input[type="range"]::-moz-range-progress {
    background-color: #007db5;
}
input[type="range"]::-moz-focus-outer {
    border: 0;
}
input[type="range"]::-moz-range-thumb {
    box-sizing: content-box;
    border: 1px solid #007db5;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}
input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
    background: #007db5;
}
input[type="range"]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    background: transparent;
    border: solid transparent;
    color: transparent;
}
input[type="range"]::-ms-fill-lower {
    background-color: #007db5;
}
input[type="range"]::-ms-fill-upper {
    background: linear-gradient(
        to right,
        rgba(0, 125, 181, 0.6) var(--buffered-width),
        rgba(0, 125, 181, 0.2) var(--buffered-width)
    );
}
input[type="range"]::-ms-thumb {
    box-sizing: content-box;
    border: 1px solid #007db5;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}
input[type="range"]:active::-ms-thumb {
    transform: scale(1.2);
    background: #007db5;
}
</style>
