import { defineStore } from "pinia";
import { winnerSongs } from "../data/data.js";

export const useAudioStore = defineStore("audioStore", {
    state: () => ({
        songs: winnerSongs,
        audioIsPlaying: false,
        currentSong: null,
        png: "kassett.png",
        gif: "kassettGif.gif",
        currentHeaderImage: "",
        audio: null,
        loadPlayer: null,
        ellapsedTime: null,
    }),
    getters: {
        headerImage() {
            let image = null;
            if (this.audioIsPlaying == true) {
                image = this.gif;
            } else {
                image = this.png;
            }
            return image;
        },
        theCurrentSong() {
            return this.currentSong;
        },
        timer() {
            return this.ellapsedTime;
        },
    },
    actions: {
        play(song) {
            if (this.audioIsPlaying == true && this.currentSong != null) {
                this.currentSong = song;
                if (this.currentSong != song) {
                    this.currentSong = song;
                }

                this.audio.pause();
                this.audio = new Audio(song.url);
                this.audio.id = "html5_audio_qx58j9a4q1";
                /*              this.audio.analytics.track("Testings", {
                    clipName: "Testings",
                }); */
                this.audio.play();
                this.audioIsPlaying = true;
                this.currentSong = song;

                /* hvis ingen sange spiller */
            } else if (
                this.audioIsPlaying == false &&
                this.currentSong == null
            ) {
                this.loadPlayer = true;
                this.currentSong = song;
                this.audio = new Audio(song.url);
                this.audio.id = "html5_audio_qx58j9a4q1";
                this.audio.play();
                this.audioIsPlaying = !this.audioIsPlaying;
            }
            /* reset og nullstill ved avsluttet sang*/
            this.audio.addEventListener("ended", () => {
                this.currentSong = null;
                this.audio = null;
                this.loadPlayer = false;
                this.audioIsPlaying = !this.audioIsPlaying;
            });
        },
        stopAudio() {
            this.currentSong = null;
            this.audioIsPlaying = !this.audioIsPlaying;
            this.loadPlayer = false;
            this.audio.pause();
            this.audio = null;
        },
    },
});
