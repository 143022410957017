<template>
    <div
        id="header"
        class="relative grid-span-1 col-start-1 h-full">
        <h2
            v-show="header"
            class="text-gray-700 text-3xl tracking-wide my-2 ml-2 xl:text-4xl">
            {{ header }}
        </h2>
        <div :class="placement">
            <img
                class="brightness-50"
                :src="require(`@/assets/images/${image}`)"
                alt="" />
            <h3 :class="style">{{ title }}<br /></h3>
        </div>

        <p class="text-sm py-4 mx-4 xl:text-md xl:mx-4">
            {{ text }}
        </p>
        <div v-show="false"><p class="ml-2 pb-2">DUE TEXT</p></div>

        <div
            valign="bottom"
            v-show="true"
            class="w-full bg-gray-100 flex justify-center py-4">
            <router-link
                class="w-full flex justify-center"
                :to="buttonLink">
                <button
                    @click="print"
                    class="button">
                    {{ buttonText }}
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        "image",
        "header",
        "title",
        "text",
        "button-text",
        "titlestyles",
        "titleplacement",
        "button-link",
    ],
    data() {
        return {
            style:
                "absolute text-7xl xl:text-9xl p-4 rounded-sm xl:mx-8" +
                " " +
                this.titlestyles,
            placement: "relative flex items-center" + " " + this.titleplacement,
        };
    },
    buttonLink: null,
};
</script>
